import { Link } from "react-router-dom";

import GallerySpecial from "./GallerySpecial";

import "./Home.css";

export default function Home() {
  return (
    <main className="main">
      <div className="main__text home__fast-links">
        <Link to="/album/recent/0"
          className="main__button main__link_as-button main__button_many">
          Последние добавления</Link>
        <Link to="/comments/0"
          className="main__button main__link_as-button main__button_many">
          Последние комментарии</Link>
        <Link to="/tag/cloud"
          className="main__button main__link_as-button main__button_many">
          Облако тегов</Link>
        <Link to="/album/random/0"
          className="main__button main__link_as-button main__button_many">
          Случайные фотографии</Link>
      </div>
      <div className="main__text main__important">
        <p>
          ВНИМАНИЕ: ЕСЛИ ВЫ ПОСЕЩАЛИ СТАРЫЙ САЙТ (ДО ОКТЯБРЯ 2022)!
        </p>
        <p>
          1. В связи с обновлением сайта и сопутствующими техническими работами,
          старый пароль от вашего аккаунта более недействителен. На странице
          для <Link to="/signin" className="main__link">входа</Link> в аккаунт
          вам необходимо выбрать сверху "Восстановить пароль". Затем введите
          адрес электронной почты, на который был зарегистрирован ваш аккаунт.
          Вскоре вам придёт письмо со ссылкой, пройдя по которой вы сможете 
          установить новый пароль. Если же вы не имеете доступа к электронной
          почте, на которую был зарегистрирован ваш аккаунт, то вам придётся
          создать новый.
        </p>
        <p>
          2. Теперь сайт адаптирован и под мобильные телефоны.
        </p>
        <p>
          3. Кнопка ниже переместит вас непосредственно в фотогалерею. Также это
          можно сделать и через меню сайта.
        </p>
        <Link to="/album/0/0"
          className="main__button main__link_as-button">
          &gt;&gt; ПЕРЕЙТИ В ФОТОГАЛЕРЕЮ &lt;&lt;</Link>
      </div>
      <p className="main__text">
        "История в фотографиях. Новороссийск, Костомарово" - это один из
        крупнейших в России архивов исторических фотографий. Он создан и 
        поддерживается Василием Михайловичем Хониным, уроженцем села Костомарово
        Воронежской области, в настоящее время проживающим в городе-герое
        Новороссийске.
      </p>
      <p className="main__text">
        В архиве находятся как исторические, так и современные фотографии: в
        общей сложности на данный момент более 20 тысяч снимков. Охват мест и 
        тем огромен, среди них основными являются:
      </p>
      <ul className="main__text">
        <li>город-герой Новороссийск;</li>
        <li>сёла Воронежской области Подгоренского района:
          Юдино, Сагуны, Колодежное, Хвощеватка;</li>
        <li>Новороссийское морское пароходство (НМП, ныне ОАО "Новошип"):
          суда и жизнь экипажей на борту, а также береговые службы;</li>
        <li>Свято-Спасский женский монастырь в селе Костомарово.</li>
      </ul>
      <p className="main__text">
        Путешествие в историю можно начать несколькими способами:
      </p>
      <ul className="main__text">
        <li><Link to="/album/0/0" className="main__link">Фотогалерея
        </Link> является наиболее естественным, классическим способом
        исследовать архив.</li>
        <li><Link to="/album/random/0" className="main__link">Случайные
        фотографии</Link> подойдут, если вы ещё не определились с интересующей
        темой или хотите открыть для себя что-то новое.</li>
        <li><Link to="/album/recent/0" className="main__link">Последние
        добавления</Link> помогут постоянным посетителям быть в курсе недавних
        обновлений.</li>
        <li><Link to="/tag/cloud" className="main__link">Облако тегов
        </Link> позволит быстро ознакомиться с доступными темами и выбрать
        интересующую без дополнительных усилий с вашей стороны.</li>
        <li><Link to="/search" className="main__link">Поиск</Link> дает 
        возможность с помощью соответствующего запроса найти именно то, что
        вас интересует, среди альбомов, фотографий и тегов.</li>
      </ul>
      <p className="main__text">
        Помимо фотографий, на сайте также имеется <Link to="/texts"
        className="main__link">раздел с текстами</Link>, где собрана некоторая
        историческая и справочная информация.
      </p>
      <p className="main__text">
        Если вы на сайте впервые, то настоятельно рекомендуем вам <Link
        to="/signin" className="main__link">зарегистрироваться</Link> - в этом
        случае вы сможете оставлять комментарии к фотографиям, альбомам и
        текстам. <Link to="/comments/0" className="main__link">Ознакомьтесь с
        последними комментариями</Link>, чтобы быть в курсе, что сейчас
        обсуждают.
      </p>
      <p className="main__text">
        Этот архив не пополняется сам по себе - пожалуйста, ознакомьтесь с
        информацией в разделе <Link to="/contacts" className="main__link">
        "Контакы"</Link>, вероятно вы можете помочь пополнить архив и сохранить
        историю.
      </p>
      <h2 className="main__heading">Последние добавления</h2>
      <GallerySpecial type="recent" quantity={6} />
      <h2 className="main__heading">Случайная фотография</h2>
      <GallerySpecial type="random" quantity={1} />
    </main>
  );
}