import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { ReactComponent as Hamburger } from "./hamburger.svg";
import { ReactComponent as Logo } from "./logo.svg";

import "./Header.css";

function Header(props) {
  const [navVisible, setNavVisible] = useState(false);
  
  return (
    <header className="header">
      <div className="header__heading">
        <Link to="/" className="header__link"
          onClick={()=>setNavVisible(false)}>
          <Logo className="header__logo" />
        </Link>
        <Link to="/" className="header__link"
          onClick={()=>setNavVisible(false)}>
          <h1 className="header__title">История в фотографиях
            <br />Новороссийск, Костомарово</h1>
        </Link>
        <button className="header__hamburger"
          onClick={()=>setNavVisible(navVisible => !navVisible)}>
          <Hamburger />
        </button>
      </div>
      <nav
        className={"header__nav " + (navVisible ? "header__nav_visible" : "")}
        onClick={()=>setNavVisible(false)}
      >
        <NavLink to="/album/0/0" className="header__link header__nav-link">
          Фото
        </NavLink>
        <NavLink to="/search" className="header__link header__nav-link">
          Поиск
        </NavLink>
        <NavLink to="/texts" className="header__link header__nav-link">
          Тексты
        </NavLink>
        <NavLink to="/contacts" className="header__link header__nav-link">
          Контакты
        </NavLink>
        {
          !!localStorage.getItem("isSignedIn")
          ? (<NavLink to="/profile" className="header__link header__nav-link">
              Профиль
            </NavLink>)
          : (<NavLink to="/signin" className="header__link header__nav-link">
              Войти
            </NavLink>)
        }
      </nav>
    </header>
  );
}

export default Header;
