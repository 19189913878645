import { Link } from 'react-router-dom';

import './Tags.css';

export default function Tags({tags, cloud}) {
  return tags.length === 0 ? "-" : (
    <ul className="tags">
      {tags.map(tag => (
        <li key={tag._id} className="tags__item">
          <Link className="tags__link" to={"/tag/"+tag._id+"/0"}>
            {tag.title + (cloud ? ` (${tag.children})` : "")}
          </Link>
        </li>
      ))}
    </ul>
  );
}