import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CommentsForm from "./CommentsForm";

import "./Comments.css";

export default function Comments(props) {
  const [comments, setComments] = useState([]);
  const [commentsList, setCommentsList] = useState([]);
  const [editComment, setEditComment] = useState();

  /**
   * @function parseNewlines - parses comment converting it to list of p's
   * @param {string} input - String with \n or \r\n symbols
   * @returns {array} - Array of "p" tags
   */
  function parseNewlines(input) {
    return input.split(/\\r\\n|\\n|\r\n|\n/).map((line, i) => <p key={i}>{line}</p>);
  }

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  useEffect(() => {
    const isAdmin  = !!localStorage.getItem("isAdmin");
    const result = comments.map((comment, index) => (
      <li className="comments__item" key={comment._id}>
        <p className="comments__head">
          {comment.user.name + (isAdmin ? (" - " + comment.user.mail) : "") +
            " - " + new Date(comment.created).toLocaleString("ru") +
            (props.parentLink ? "" : " - #" + (index + 1))}
          {isAdmin && <button
            onClick={e=>deleteComment(e, comment._id)}
            className="main__button comments__button"
            >X</button>}
          {isAdmin && <button
            onClick={e=>deleteAllComment(e, comment._id)}
            className="main__button comments__button"
            >Бан</button>}
          {isAdmin && !props.noForm && <button
            onClick={e=>handleEditComment(comment)}
            className="main__button comments__button"
            >{editComment?._id === comment._id ? "Отм." : "Ред."}</button>}
        </p>
        <p className="comments__title">{comment.title}</p>
        {parseNewlines(comment.description)}
        {props.parentLink &&
          <Link to={(comment.parentIs === "Photo" ? "/photo/" : "/album/") +
            comment.parent + (comment.parentIs === "Photo" ? "" : "/0")} className="main__link">
            Перейти к {comment.parentIs === "Photo" ? "фотографии" : "альбому"}.
          </Link>}
      </li>
    ))
    setCommentsList(result);
  }, [comments, props.parentLink, editComment])

  const deleteComment = async (event, commentId) => {
    const confirmed = window.confirm("Вы точно хотите удалить комментарий?");
    if (confirmed) {
      const response = await fetch("/api/comment/delete/" + commentId);
      if (response.ok) setComments(
        comments => comments.filter(comment => comment._id !== commentId)
      );
    }
  }

  const deleteAllComment = async (event, commentId) => {
    const confirmed = window.confirm("Вы точно хотите удалить все " + 
      "комментарии пользователя и запретить ему комментировать?");
    if (confirmed) {
      const response = await fetch("/api/comment/deleteAll/" + commentId);
      if (response.ok) window.location.reload();
    }
  }

  const handleEditComment = comment => {
    setEditComment(prev => {
      return prev?._id === comment._id ? undefined : comment;
    })
  }

  return (
    <section>
      <ul className="comments__list">{commentsList}</ul>
      {!props.noForm && !!localStorage.getItem("isSignedIn") &&
        <CommentsForm setComments={setComments} editComment={editComment}
          setEditComment={setEditComment} />}
    </section>
  )
}
