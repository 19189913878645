import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormInput from "./FormInput";

import "./FormCaptcha.css";

/**
 * @function FormCaptcha
 * @param {object} props 
 * @param {function} setCaptcha - setState()
 * @param {string} captcha - state
 * @returns 
 */

export default function FormCaptcha(props) {
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState("");

  useEffect(()=>{
    async function fetchCaptcha() {
      const response = await fetch("/api/captcha");
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message);
      }
      setCaptcha(responseBody.captcha);
    }
    if (props.refresh === true) {
      fetchCaptcha();
      props.setRefresh(false);
    }
  }, [props.refresh])

  return (
    <>
      <div className="form-captcha" dangerouslySetInnerHTML={{__html: captcha}}></div>
      <FormInput type="text" name="captchaText" required value={props.captcha}
        setValue={props.setCaptcha} label="Введите символы"
        classes="form-captcha__input" />
    </>
  )
};