import AdminPhotoCount from "./AdminPhotoCount";
import AdminTags from "./AdminTags";
import AdminUsers from "./AdminUsers";
import { Link, Route, Routes } from "react-router-dom";

import "./Admin.css";

export default function Admin(props) {
  return (<main className="main">
    <nav>
      <Link to="users" className="main__button main__link_as-button main__button_many">Пользователи</Link>
      <Link to="tags" className="main__button main__link_as-button main__button_many">Теги</Link>
      <Link to="photo-count" className="main__button main__link_as-button main__button_many">Число фотографий</Link>
    </nav>
    <Routes>
      <Route path="photo-count" element={<AdminPhotoCount />} />
      <Route path="tags" element={<AdminTags />} />
      <Route path="users" element={<AdminUsers />} />
    </Routes>
  </main>)
}