import { Link } from 'react-router-dom';

import "./Breadcrumbs.css";

export default function Breadcrumbs({ album }) {
  let next = album;
  const links = [];
  while (next) {
    links.unshift(
      <li className="breadcrumbs__item" key={next._id}><Link
        to={"/album/" + next._id + "/0"}
        className="main__link breadcrumbs__link"
      >{next.title}</Link></li>);
    next = next.parent;
  }
  return <ul className="breadcrumbs">{links}</ul>;
}