import { Link, useParams } from "react-router-dom";
import "./GalleryPage.css";

export default function GalleryPage(props) {
  const params = useParams();
  const items = [...props.album.childAlbums, ...props.album.childPhotos];

  function getThumbPath(item) {
    if (item.cover) return item.cover.filePath;
    if (item.cover === null) return "/nothumb.png";
    if (item.filePath) return item.filePath;
    return "/nothumb.png";
  }

  const handleGalleryItemClick = event => {
    localStorage.setItem("neighboursIn", `${props.type} ${params.id}`)
  }

  return (
    <section className="gallery-tile__page">
      {items.map(item => (
        <Link target={(props.type === "random" || props.album._id === "random" || props.type === "recent" || props.album._id === "recent")? "_blank" : ""} key={item._id}
          to={item.cover !== undefined ? "/album/" + item._id + "/0" : "/photo/" + item._id} onClick={handleGalleryItemClick}>
          <figure className="gallery-tile">
          <img className="gallery-tile__image" alt="Фотография" height="300"
            width="300" src={"/thumbs" + getThumbPath(item)} />
          <figcaption className="gallery-tile__caption">
            <span className="gallery-tile__title">
              {!!item.cover && "Альбом: "}{item.title}
            </span>
            <span className="gallery-tile__meta">
              {new Date(item.created).toLocaleDateString("ru")}
            </span>
            <span className="gallery-tile__meta">
              👁{item.views}
            </span>
          </figcaption>
          </figure>
        </Link>
      ))}
    </section>);
}