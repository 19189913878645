import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Form from "./Form/Form";
import FormInput from "./Form/FormInput";
import FormRadio from "./Form/FormRadio";
import GalleryPage from "./GalleryPage";
import Pager from "./Pager";
import Tags from "./Tags";
import Texts from "./Texts";

import "./Search.css";

export default function Search(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchType, setSearchType] = useState("photo");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  const [message, setMessage] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [numberOfItems, setNumberOfItems] = useState(0);
    
  const fetchSearch = async () => {
    if (!searchParams.get("page")
      || !searchParams.get("searchType") 
      || !searchParams.get("search")) return;
    const response = await fetch("/api/search?" + searchParams);
    const responseBody = await response.json();
    if (!response.ok) {
      setMessage(responseBody.message)
    } else {
      setMessage("")
      setNumberOfItems(responseBody.numberOfItems);
      setSearchResults(responseBody.searchResult);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setPage(0);
    updateSearchParams();
  };

  const updateSearchParams = () => {
    setSearchParams(new URLSearchParams({
      searchType,
      page,
      search
    }))
  }
  
  useEffect(()=>{
    const params = Object.fromEntries(searchParams.entries());
    if (params.searchType) setSearchType(params.searchType);
    if (!isNaN(params.page)) setPage(Number(params.page));
    if (params.search) {
      setSearch(params.search);
    }
    updateSearchParams();
  }, [])

  useEffect(()=>{
    setSearchResults([]);
    setNumberOfItems(0);
    setPage(0);
    updateSearchParams();
  }, [searchType])

  useEffect(()=>{
    updateSearchParams();
  }, [page, search]);

  useEffect(() => {
    fetchSearch();    
  }, [searchParams]);

  return (
    <main className="main">
      <Form handleSubmit={handleSubmit} message={message}
        classes="search-form">
        <FormRadio options={[
          {value: "photo", label: "Фотографии", default: true},
          {value: "album", label: "Альбомы"},
          {value: "tag", label: "Теги"},
          {value: "text", label: "Тексты"}
          ]} propertyName="actionType" value={searchType} setValue={setSearchType} />
        <FormInput type="text" name="search" value={search} setValue={setSearch}
          label="Запрос:" />
      </Form>
      {searchType === "album" && searchResults.length !== 0 && <>
          <Pager numberOfItems={numberOfItems} setPage={setPage} page={page} />
          <GalleryPage album={{childAlbums: searchResults, childPhotos: []}} />
          <Pager numberOfItems={numberOfItems} setPage={setPage} page={page} />
        </>}
      {searchType === "photo" && searchResults.length !== 0  && <>
          <Pager numberOfItems={numberOfItems} setPage={setPage} page={page} />
          <GalleryPage album={{childAlbums: [], childPhotos: searchResults}} />
          <Pager numberOfItems={numberOfItems} setPage={setPage} page={page} />
        </>}
      {searchType === "tag" && <Tags tags={searchResults} />}
      {searchType === "text" && <Texts texts={searchResults} />}
    </main>
  );
}