import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import Comments from "./Comments";
import Meta from "./Meta";

import "./Photo.css";

export default function Photo() {
  const params = useParams();
  const [img, setImg] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [photo, setPhoto] = useState([]);
  const [neighbours, setNeighbours] = useState({previous: "", next: ""});
  const [imgMeta, setImgMeta] = useState("");
  const navigate = useNavigate();
  
  useEffect(() => {
    async function fetchPhoto() {
      setIsLoading(true);
      const response = await fetch("/api/photo/" + params.id);
      if (!response.ok) navigate("/404", {replace: true});
      const responseBody = await response.json();
      setPhoto(responseBody);
      setIsLoading(false);
      const resultImg = await fetch("/api/photo/" + params.id + "/img");
      const buffer = await resultImg.arrayBuffer();
      const src = URL.createObjectURL(new File([buffer], "img.png", {type: "image/png"}));
      const [nType, nId] = localStorage.getItem("neighboursIn").split(" ");
      const nQuery = `?type=${nType}&id=${nId}`;
      const responseNeighbours = await fetch("/api/photo/" + params.id + "/neighbours" + nQuery);
      if (responseNeighbours.ok && responseNeighbours.message !== "error") {
        const neighboursObject = await responseNeighbours.json();
        setNeighbours(neighboursObject);
      }
      setImg(src);
      const guestWidth = responseBody.width >= responseBody.height
        ? 700 : Math.ceil(responseBody.width * 700 / responseBody.height);
      const guestHeight = responseBody.height >= responseBody.width
      ? 700 : Math.ceil(responseBody.height * 700 / responseBody.width);
      setImgMeta(responseBody.width + "x" + responseBody.height + " (" +
      guestWidth + "x" + guestHeight + ")" )
    }
    fetchPhoto();
  }, [params.id])

  const makeCover = async event => {
    const response = await fetch("/api/photo/" + params.id + "/makecover");
    const responseBody = await response.json();
    alert(responseBody.message);
  }

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : (
    <main className="main main-photo">
      <Breadcrumbs album={photo.parent} />
      {neighbours.previous &&
        <Link to={"/photo/" + neighbours.previous}
        className="main__button main__link_as-button main__button_many">
        &lt; Предыдущее фото</Link>}
      {neighbours.next &&
        <Link to={"/photo/" + neighbours.next}
        className="main__button main__link_as-button main__button_many">
        Следующее фото &gt;</Link>}
      {localStorage.getItem("isAdmin") &&
        <Link to={"/photo/" + params.id + "/edit"}
        className="main__button main__link_as-button main__button_many">
        Редактировать фото</Link>}
      {localStorage.getItem("isAdmin") &&
        <Link to={"/photo/" + params.id + "/move"}
        className="main__button main__link_as-button main__button_many">
        Переместить фото</Link>}
      {localStorage.getItem("isAdmin") &&
        <button onClick={makeCover}
        className="main__button main__button_many">
        Сделать обложкой</button>}
      <img
        className="main-photo__photo"
        src={img}
        alt={photo.title}
        onClick={e=>e.target.classList.toggle("main-photo__photo_fullsize")}
      />
      <Meta
        title={photo.title}
        description={photo.description}
        created={photo.created}
        views={photo.views}
        tags={photo.tags}
        imgMeta={imgMeta}
      />
      <Comments comments={photo.comments}/>
    </main>
  );
}