import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GalleryPage from "./GalleryPage";
import Pager from "./Pager";

function Tag() {
  const params = useParams();
  const navigate = useNavigate();
  const [tag, setTag] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchTag() {
      setIsLoading(true);
      const response = await fetch("/api/tag/" + params.id + "/" + params.page);
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message, {replace: true});
      }
      setTag(responseBody);
      setIsLoading(false);
    }
    fetchTag();
  }, [params.id, params.page])

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : (
    <main className="main main-album">
      <h2>{tag.title}</h2>
      <Pager numberOfItems={tag.childCount} numberOfItemsPerPage={24}/>
      <GalleryPage album={tag} type="tag" />
      <Pager numberOfItems={tag.childCount} numberOfItemsPerPage={24}/>
    </main>
  );
}

export default Tag;
