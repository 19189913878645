import { useEffect, useState } from "react"

import "./AdminUsers.css";

export default function AdminUsers(props) {
  const [users, setUsers] = useState([]);
  const [update, setUpdate] = useState(true);
  const [usersOnline, setUsersOnline] = useState([]);

  useEffect(() => {
    async function fetchUsers() {
      const response = await fetch("/api/user/all");
      const responseBody = await response.json();
      if (response.ok) setUsers(responseBody);
      setUpdate(false);
    }
    if (update) fetchUsers();
  }, [update])

  useEffect(() => {
    async function fetchOnline() {
      const response = await fetch("/api/user/online");
      const responseBody = await response.json();
      console.log(responseBody)
      if (response.ok) setUsersOnline(responseBody.users);
    }
    fetchOnline();
  }, [])

  const handleActivated = async userId => {
    const response = await fetch("/api/user/activate/" + userId);
    const responseBody = await response.json();
    alert(responseBody.message);
    setUpdate(true);
  }

  return (<>
    <p className="admin-users__online">Онлайн: {usersOnline.join(", ")} и гости.</p>
    <table className="admin-users__table">
      <thead>
        <tr>
          <th>Почта</th>
          <th>Имя</th>
          <th>Управление</th>
        </tr>
      </thead>
      <tbody>
        {users.map(user => {
          return (<tr key={user._id}>
            <td>{user.mail}</td>
            <td>{user.name}</td>
            <td>
              <button className="main__button admin-users__button" onClick={() => navigator.clipboard.writeText(user._id)}>ID</button>
              <button className="main__button admin-users__button" onClick={() => handleActivated(user._id)}>{user.activated ? "Деакт." : "Акт."}</button>
            </td>
          </tr>)
        })}
      </tbody>
    </table>
  </>)
}