import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Texts.css";

export default function Texts(props) {
  const [textTiles, setTextTiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function fetchTexts() {
      setIsLoading(true);
      let texts = props.texts;
      if (!props.texts) {
        const response = await fetch("/api/text/all");
        const responseBody = await response.json();
        if (!response.ok) {
          texts = [];
        } else {
          texts = responseBody.texts;
        }
      }

      const tiles = [];
      for (const text of texts) {
        tiles.push(
          <article key={text._id} className="text-tile">
            <h2 className="text-tile__title">{text.title}</h2>
            <div className="text-tile__meta">
              <span>{new Date(text.created).toLocaleDateString("ru")}</span>
              <span>👁{text.views}</span>
            </div>
            <p className="main__text">{text.short}</p>
            <Link className="main__link" to={"/texts/" + text._id}>Читать дальше</Link>
          </article>
        )
      }
      setTextTiles(tiles);
      setIsLoading(false);
    }
    fetchTexts();
  }, [props.texts]);

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : 
    props.texts ? (<div className="main-texts">{textTiles}</div>) :
    (<main className="main main-texts">{textTiles}</main>);
}