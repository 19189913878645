import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "./Form/Form";
import FormDatalist from "./Form/FormDatalist";
import FormInput from "./Form/FormInput";
import FormInputFile from "./Form/FormInputFile";

import "./PhotoForm.css";

export default function PhotoForm(props) {
  const imageRef = useRef(null);
  const watermarkRef = useRef(null);
  const params = useParams();
  const navigate = useNavigate();
  
  const [allTags, setAllTags] = useState([]);
  const [allWatermarks, setAllWatermarks] = useState([]);

  const [watermark, setWatermark] = useState({
    left: 0,
    top: 0,
    file: ""
  });

  useEffect(() => {
    async function fetchTags() {
      const response = await fetch("/api/tag/cloud");
      const responseBody = await response.json();
      setAllTags(responseBody);
    }
    async function fetchWatermarks() {
      const response = await fetch("/api/watermarks");
      const responseBody = await response.json();
      setAllWatermarks(responseBody);
    }
    async function fetchPhoto() {
      setIsLoading(true);
      const response = await fetch("/api/photo/" + params.id + "/edit");
      if (response.status === 403) navigate(-1);

      const responseBody = await response.json();
      setPhoto(responseBody);
      setTitle(responseBody.title);
      setDescription(responseBody.description);
      setTags(responseBody.tags.map(tag => tag.title));
      if (responseBody.watermark?.file) setWatermark({
        top: responseBody.watermark.top,
        left: responseBody.watermark.left,
        file: responseBody.watermark.file.file
      });
      
      const responseI = await fetch("/api/photo/" + params.id + "/img?wm=nowm");
      const buffer = await responseI.arrayBuffer();
      setImage(new File([buffer], "img.png"));

      setIsLoading(false);
    }
    async function fetchAlbum() {
      setIsLoading(true);
      const response = await fetch("/api/album/" + params.id + "/edit");
      if (response.status === 403) navigate(-1);

      const responseBody = await response.json();
      setAlbum(responseBody);
      setTitle(responseBody.title);
      setDescription(responseBody.description);
      setTags(responseBody.tags.map(tag => tag.title));
      setSort(responseBody.sort.by + " " + responseBody.sort.order);
      
      setIsLoading(false);
    }
    fetchTags();
    fetchWatermarks();
    if (props.mode === "edit photo") fetchPhoto();
    else if (props.mode === "edit album") fetchAlbum();
  }, [])

  const [photo, setPhoto] = useState({});
  const [album, setAlbum] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState("title 1");
  
  const [isLoading, setIsLoading] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async event => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (props.mode.includes("photo")) {
      formData.append("image", image);
      formData.append("originalFile", image.name);
    }
    if (props.mode.includes("album")) formData.append("sort", sort);
    formData.append("tagNames", tags);
    formData.append("parent",
      new URLSearchParams(window.location.search).get("album"));
    if (watermark.file) {
      formData.append("watermarkTop", Math.ceil(watermark.top));
      formData.append("watermarkLeft", Math.ceil(watermark.left));
      formData.append("watermarkFile", watermark.file);
    }
    let response;
    if (props.mode === "add photo") {
      response = await fetch("/api/photo/add", {
        method: "POST",
        body: formData
      });
    } else if (props.mode === "edit photo") {
      response = await fetch("/api/photo/" + params.id + "/edit", {
        method: "POST",
        body: formData
      });
    } else if (props.mode === "add album") {
      response = await fetch("/api/album/add", {
        method: "POST",
        body: formData
      });
    } else if (props.mode === "edit album") {
      response = await fetch("/api/album/" + params.id + "/edit", {
        method: "POST",
        body: formData
      });
    }
    if (response.status === 403) navigate(-1);

    const responseBody = await response.json();
    setMessage(responseBody.message);
    if (response.ok) navigate(-1);
  }

  const handleMouseDown = event => {
    event.preventDefault();
    if (event.target === watermarkRef.current) {
      const watermarkRect = event.target.getBoundingClientRect();
      setWatermark(s=>{return {
        ...s,
        offsetLeft: event.clientX - watermarkRect.left,
        offsetTop: event.clientY - watermarkRect.top,
        drag: true
      }});
    }
  };
  const handleMouseMove = event => {
    event.preventDefault();
    if (!watermark.drag) return;
    const containerRect = imageRef.current.getBoundingClientRect();
    const mouseInContainerPsnX = event.clientX - containerRect.left;
    const mouseInContainerPsnY = event.clientY - containerRect.top;
    let top = mouseInContainerPsnY - watermark.offsetTop;
    let left = mouseInContainerPsnX - watermark.offsetLeft;
    if (top < 0) top = 0;
    if (left < 0) left = 0;
    if (top > containerRect.height - watermarkRef.current.height) {
      top = containerRect.height - watermarkRef.current.height;
    }
    if (left > containerRect.width - watermarkRef.current.width) {
      left = containerRect.width - watermarkRef.current.width;
    }
    setWatermark(s => {return { ...s, top, left }});
  }

  const handleDelete = async event => {
    event.preventDefault();
    const isAlbum = props.mode.includes("album");
    const confirm = window.confirm(
      "Вы действительно хотите удалить " + (isAlbum ? "альбом" : "фото") + "?");
    if (confirm) {
      const response = await fetch(
        "/api/" + (isAlbum ? "album" : "photo") + "/" + params.id + "/delete");
      if (response.status === 403) navigate(-1);
      
      const responseBody = await response.json();
      alert(responseBody.message);
      if (response.ok) navigate(
        "/album/" + (isAlbum ? album.parent._id : photo.parent._id) + "/0");
    }
  }

  const handleWatermarkChange = event => {
    setWatermark(wm => {
      return {
        ...wm,
        top: 0,
        left: 0,
        file: event.target.value
      }
    });
  }

  return !isLoading && (<main className="main">
    <Form handleSubmit={handleSubmit} message={message} classes="form_gallery">
      <FormInput label="Название" name="title" required={true} type="text"
        value={title} setValue={setTitle} />
      <FormInput label="Описание" name="description" type="text"
        value={description} setValue={setDescription} />
      {!!allTags.length && <FormDatalist label="Выберите теги" button="Добавить"
        name="tags" field="title" data={allTags} setValue={setTags} value={tags}
        />}
      {props.mode.includes("album") && 
        <select value={sort} onChange={e=>setSort(e.target.value)}
          class="form__input">
          <option value="title 1" key="title 1">Название: по возрастанию</option>
          <option value="title -1" key="title -1">Название: по убыванию</option>
          <option value="created 1" key="created 1">Загружено: по возрастанию</option>
          <option value="created -1" key="created -1">Загружено: по убыванию</option>
        </select>}
      {props.mode.includes("photo") && <FormInputFile
        label="Загрузите фотографию" name="image"
        value={image} setValue={setImage} />}
      {props.mode.includes("photo") && <p style={{margin: 0}}>Имя ранее загруженного файла: {photo?.originalFile}</p>}
      {props.mode.includes("photo") && 
        <select value={watermark?.file} onChange={handleWatermarkChange}
          class="form__input">
          <option value="" key="0">-- Выберите водяной знак --</option>
          {allWatermarks.map(wm => <option value={wm.file} key={wm._id}>{wm.file}</option>)}
        </select>}
      {props.mode.includes("photo") && <div
        className="photo-form__image-preview"
        onMouseDown={handleMouseDown}
        onMouseUp={()=>setWatermark(s=>{return {...s, drag: false}})}
        onMouseLeave={()=>setWatermark(s=>{return {...s, drag: false}})}
        onMouseMove={handleMouseMove}
      >
        <img src={image ? URL.createObjectURL(image) : ""} alt="" ref={imageRef}
          className="photo-form__image" />
        {!!image && watermark.file && <img className="photo-form__watermark"
          ref={watermarkRef} src={"/watermarks/" + watermark?.file}
          alt="Водяной знак" style={{
            left: watermark?.left,
            top: watermark?.top
          }} />}
      </div>}
    </Form>
    {props.mode.includes("edit") && <div className="photo-form__remove">
      <button onClick={handleDelete} className="main__button">
      Удалить {props.mode.includes("album") ? "альбом" : "фото"}</button></div>}
  </main>);
}