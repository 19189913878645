import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Form from "./Form/Form";
import FormInput from "./Form/FormInput";
import FormTextarea from "./Form/FormTextarea";

import "./CommentsForm.css";

function CommentsForm(props) {
  const [commentTitle, setCommentTitle] = useState("");
  const [commentDescription, setCommentDescription] = useState("");
  const [message, setMessage] = useState("");
  const params = useParams();

  const handleSubmit = async event => {
    event.preventDefault();
    if (!localStorage.getItem("isSignedIn") || !commentDescription) return;

    const formData = new FormData();
    formData.set("title", commentTitle);
    formData.set("description", commentDescription);
    formData.set("parent", params.id);

    let response;
    setMessage("Отправляем комментарий...");
    if (props.editComment) {
      response = await fetch("/api/comment/edit/" + props.editComment?._id, {
        method: "POST",
        body: formData
      });
    } else {
      response = await fetch("/api/comment/post", {
        method: "POST",
        body: formData
      });
    }
    const responseBody = await response.json();

    if (response.ok) {
      setCommentTitle("");
      setCommentDescription("");
      if (props.editComment) {
        const newComment = responseBody.comment;
        props.setComments(comments => {
          const oldComment = comments.find(c => c._id === newComment._id);
          oldComment.title = newComment.title;
          oldComment.description = newComment.description;
          return comments;
        });
        props.setEditComment();
      } else {
        props.setComments(comments => [...comments, responseBody.comment]);
      }
    }
    
    setMessage(responseBody.message);
  };

  useEffect(()=>{
    if (props.editComment) {
      setCommentTitle(props.editComment.title);
      setCommentDescription(props.editComment.description);
    } else {
      setCommentTitle("");
      setCommentDescription("");
    }
  }, [props.editComment])

  return (
    <Form handleSubmit={handleSubmit} message={message}
      classes="comments__form">
      <FormInput label="Заголовок (не обязателен)" name="title" type="text"
        setValue={setCommentTitle} value={commentTitle} />
      <FormTextarea label="Комментарий" name="description" rows={5} 
        required={true} setValue={setCommentDescription}
        value={commentDescription} />
    </Form>
  )
}

export default CommentsForm;