import Tags from './Tags';

import "./Meta.css";

function Meta(props) {
  return (
    <section className="meta">
      <p className="meta__text-static">Название:</p>
      <p className="meta__text-dynamic">{props.title}</p>
      <p className="meta__text-static">Описание:</p>
      <p className="meta__text-dynamic">{props.description || "-"}</p>
      <p className="meta__text-static">Создано:</p>
      <p className="meta__text-dynamic">
        {new Date(props.created).toLocaleDateString("ru")}
      </p>
      <p className="meta__text-static">Просмотры:</p>
      <p className="meta__text-dynamic">{props.views}</p>
      {
        !!props.imgMeta &&
        <>
          <p className="meta__text-static">Размеры:</p>
          <p className="meta__text-dynamic">{props.imgMeta}</p>
        </>
      }
      {
        !!props.elements &&
        <>
          <p className="meta__text-static">Элементов:</p>
          <p className="meta__text-dynamic">{props.elements}</p>
        </>
      }
      <p className="meta__text-static">Теги:</p>
      <Tags tags={props.tags || []} />
    </section>
  );
}

export default Meta;