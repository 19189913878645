import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "./Form/Form";
import FormInput from "./Form/FormInput"
import "./Profile.css";
import { usePasswordValidation } from "../hooks/usePasswordValidation";

function Profile(props) {
  const navigate = useNavigate();
  const [editName, setEditName] = useState(false);
  const [name, setName] = useState(localStorage.getItem("userName"));
  const [message, setMessage] = useState("");
  const [editPassword, setEditPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [isValidPassword] = usePasswordValidation(
    oldPassword,
    newPassword,
    repeatNewPassword,
    setMessage
  );

  async function signout() {
    const response = await fetch("/api/user/signout");
    const responseBody = await response.json();
    if (response.ok) {
      localStorage.removeItem("userName");
      localStorage.removeItem("isSignedIn");
      localStorage.removeItem("isAdmin");
      navigate("/signin", { replace: true });
    } else {
      setMessage(responseBody.message);
    }
  }

  const handleNameEditCancel = event => {
    setEditName(editName=>!editName);
    setName(localStorage.getItem("userName"));
  }

  const handleNameEditSave = async event => {
    if (name === localStorage.getItem("userName")) {
      setEditName(editName=>!editName);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    const response = await fetch("/api/user/edit", {
      method: "POST",
      body: formData
    });
    const responseBody = await response.json();
    if (response.ok) {
      localStorage.setItem("userName", responseBody.name);
      setName(responseBody.name)
      setEditName(editName=>!editName);
    }
    setMessage(responseBody.message)
  }

  const handlePasswordEditSave = async event => {
    if (!isValidPassword) return;
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);
    const response = await fetch("/api/user/edit", {
      method: "POST",
      body: formData
    });
    const responseBody = await response.json();
    if (response.ok) {
      setEditPassword(editPassword=>!editPassword);
    }
    setMessage(responseBody.message)
  }

  return (
    <main className="main">
      <Form handleSubmit={e=>e.preventDefault()} message={message} noSubmit={true}>
        <FormInput type="text" name="name" value={name} setValue={setName}
          label="Отображаемое имя" disabled={!editName} />
        {!editName && <button onClick={()=>setEditName(editName=>!editName)}
          className="main__button main__button_unstretch profile__button" type="button">Изменить имя</button>}
        {editName &&
          <div className="profile__buttons">
            <button onClick={handleNameEditSave}
              className="main__button profile__button" type="button">Сохранить</button>
            <button onClick={handleNameEditCancel}
              className="main__button profile__button" type="button">Отменить</button>
          </div>}
        {editPassword && <FormInput type="password" name="old-password"
          setValue={setOldPassword} label="Введите старый пароль"
          disabled={!editPassword} value={oldPassword} />}
        {editPassword && <FormInput type="password" name="new-password"
          setValue={setNewPassword} label="Введите новый пароль"
          disabled={!editPassword} value={newPassword} />}
        {editPassword && <FormInput type="password" name="repeat-new-password"
          setValue={setRepeatNewPassword} label="Повторите новый пароль"
          disabled={!editPassword} value={repeatNewPassword} />}
        {!editPassword && <button
          onClick={()=>setEditPassword(editPassword=>!editPassword)}
          className="main__button main__button_unstretch profile__button" type="button">Изменить пароль</button>}
        {editPassword &&
          <div className="profile__buttons">
            <button onClick={handlePasswordEditSave}
              className="main__button profile__button" type="button">Сохранить</button>
            <button onClick={()=>setEditPassword(editPassword=>!editPassword)}
              className="main__button profile__button" type="button">Отменить</button>
          </div>}
        <button onClick={signout} className="main__button main__button_unstretch profile__button" type="button">
          Выйти из аккаунта
        </button>
        {!!localStorage.getItem("isAdmin") && <Link to="/admin"
          className="main__button main__link_as-button main__button_unstretch">
          Администрирование</Link>}
      </Form>
    </main>
  );
}

export default Profile;
