import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Tags from "./Tags";

export default function TagCloud() {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchTagCloud() {
      setIsLoading(true);
      const response = await fetch("/api/tag/cloud");
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message, {replace: true});
      }
      setTags(responseBody);
      setIsLoading(false);
    }
    fetchTagCloud();
  }, [])

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : (
    <main className="main main-album">
      <h2 className="main__heading">Облако тегов</h2>
      <Tags tags={tags} cloud={true} />
    </main>
  );
}