import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./index.css";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const formData = new FormData();
    formData.set("errorStack", error.stack);
    formData.set("componentStack", errorInfo.componentStack);
    formData.set("locationHref", window.location.href);
    fetch("/api/error", {
      method: "POST",
      body: formData
    });
  }

  render() {
    if (this.state.hasError) {
      return (<div className="error-boundary">
        Что-то пошло не так. Пожалуйста, перезагрузите страницу.
      </div>);
    }
    return this.props.children; 
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();