import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer";

import Activate from "./components/Activate";
import Album from "./components/Album";
import CommentsRecent from "./components/CommentsRecent";
import Contacts from "./components/Contacts";
import ContactsNoForm from "./components/ContactsNoForm";
import ErrorPage from "./components/ErrorPage";
import Home from "./components/Home";
import MoveForm from "./components/MoveForm";
import Photo from "./components/Photo";
import PhotoForm from "./components/PhotoForm";
import Profile from "./components/Profile";
import ResetPassword from "./components/ResetPassword";
import Search from "./components/Search";
import Signin from "./components/Signin";
import Tag from "./components/Tag";
import TagCloud from "./components/TagCloud";
import Text from "./components/Text";
import Texts from "./components/Texts";

import Admin from "./components/Admin/Admin";

import "./App.css";

function App() {
  const location = useLocation();
  useEffect(()=>{
    async function fetchIsSignedIn() {
      const response = await fetch("/api/user/issignedin");
      const responseBody = await response.json();
      if (response.ok && responseBody.isSignedIn === false) {
        localStorage.removeItem("userName");
        localStorage.removeItem("isSignedIn");
        localStorage.removeItem("isAdmin");
      }
    }
    window.scrollTo(0,0);
    fetchIsSignedIn()
  }, [location]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="activate/:id" element={<Activate />} />
        <Route path="admin/*" element={<Admin />} />
        <Route path="album">
          <Route path="add" element={<PhotoForm mode="add album" />} />
          <Route path=":id/:page" element={<Album />} />
          <Route path=":id/edit" element={<PhotoForm mode="edit album" />} />
        </Route>
        <Route path="contacts" element={<ContactsNoForm />} />
        <Route path="comments/:page" element={<CommentsRecent />} />
        <Route path="photo">
          <Route path="add" element={<PhotoForm mode="add photo" />} />
          <Route path=":id" element={<Photo />} />
          <Route path=":id/edit" element={<PhotoForm mode="edit photo" />} />
          <Route path=":id/move" element={<MoveForm />} />
        </Route>
        <Route path="profile" element={<Profile />} />
        <Route path="reset/:token" element={<ResetPassword />} />
        <Route path="search" element={<Search />} />
        <Route path="signin" element={<Signin />} />
        <Route path="tag">
          <Route path="cloud" element={<TagCloud />}/>
          <Route path=":id/:page" element={<Tag />}/>
        </Route>
        <Route path="texts">
          <Route index element={<Texts />} />
          <Route path=":id" element={<Text />} />
        </Route>
        <Route path="error" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
