import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Comments from "./Comments";

function Text() {
  const params = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function fetchText() {
      setIsLoading(true);
      const response = await fetch("/api/text/" + params.id);
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message, {replace: true});
      }
      setText(responseBody);
      setIsLoading(false);
    }
    fetchText();
  }, [params.id]);

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : (
    <main className="main main__text">
      <h2>{text.title}</h2>
      <article dangerouslySetInnerHTML={{__html: text.description}}></article>
      <Comments comments={text.comments} />
    </main>
  );
}

export default Text;
