
import { useEffect, useState } from "react";
import "./Form.css";
import "./FormDatalist.css";

/**
 * @function FormInput
 * @param {object} props 
 * @param {string} label - label tag innerText
 * @param {string} button - button tag innerText
 * @param {string} name - name of input tag
 * @param {boolean} required - if input is required
 * @param {array} value - state
 * @param {function} setValue - setState()
 * @param {array} data - for datalist
 * @param {string} field - field to be used as option value
 * @returns 
 */

export default function FormInput(props) {
  const [dataList, setDataList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(()=>{
    setDataList(props.data.map(item=><option key={item._id} value={item[props.field]}/>));
  }, [props.data, props.field])

  const handleClick = event => {
    event.preventDefault();
    if (!props.value.includes(inputValue) && inputValue) {
      props.setValue(value => [...value, inputValue]);
    }
    setInputValue("");
  };

  const handleTagClick = event => {
    event.preventDefault();
    props.setValue(value => value.filter(item => item !== event.target.innerText));
  }

  return (
    <>
      <label htmlFor={props.name}>{props.label}</label>
      <div className="form__input-button-group">
        <input list="datalist" name={props.name} id={props.name} value={inputValue}
          onChange={e=>setInputValue(e.target.value)}
          className="form__input form__input_ibg" />
        <button onClick={handleClick}
          className="main__button form__button_ibg">{props.button}</button>
      </div>
      <ul className="tags">{props.value.map((item, index) => <li 
        className="tags__item form-datalist__tag" key={index}
        onClick={handleTagClick}>{item}</li>)}</ul>
      <datalist id="datalist">
        {dataList}
      </datalist>
    </>
  )
};