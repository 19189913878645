export default function ContactsNoForm() {
  return (
    <main className="main">
      <p className="main__text">
        Данная коллекция пополняется не только стараниями владельца сайта,
        Василия Михайловича Хонина, но и в том числе благодаря его посетителям,
        готовым поделиться фотографиями из своих коллекций и семейных архивов.
        Не один музей и архив уже дали доступ к своим хранилищам для сбора и
        оцифровки фотографий. Десятки посетителей временно предоставляли свои
        снимки и альбомы с той же целью. Всем они были возвращены в целости и
        сохранности в кратчайшие сроки, как только было завершено сканирование.
      </p>
      <p className="main__text">
        Если у вас есть старые фотографии, открытки, фотоальбомы, а также прочие
        бумажные источники, то вы можете оставить комментарий под любым фото для
        связи с владельцем сайта, Василием Михайловичем Хониным. Вероятно, ваших
        снимков и подобных им может не быть в архиве сайта - тогда они будут
        оцифрованы, обработаны и выложены (без/с указанием владельца - по вашему
        желанию).
      </p>
      <p className="main__text">
        Также вы можете оставить комментарий под любым фото, если у вас есть
        пожелания и предложения по самому сайту, или жалобы на его работу.
      </p>
    </main>
  );
}