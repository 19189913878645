import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./MoveForm.css";

export default function MoveForm() {
  const [tree, setTree] = useState([]);
  const [album, setAlbum] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    async function fetchTree() {
      function nodesToList(nodes) {
        return nodes.map(node => {return(
          <li className="move-form__list-item" key={node._id}>
            <button
              className={"main__button move-form__button move-form__list_hidden" + (node.children.length === 0 ? " move-form__button_hidden" : "")}
              onClick={e=>{
                e.target.classList.toggle("move-form__list_hidden");
                if (e.target.innerText === "+") e.target.innerText = "-";
                else e.target.innerText = "+";
              }}>+</button>
            <input type="radio" id={node._id} name="album" value={node._id}
                onChange={e => setAlbum(e.target.value)} className="move-form__radio"/>
            <label htmlFor={node._id} className="move-form__label">{node.title}</label>
            {(node.children.length !== 0) && <ul className="move-form__list">{nodesToList(node.children)}</ul>}
          </li>
        )})
      }

      const response = await fetch("/api/album/tree");
      const albums = await response.json();
      
      albums.forEach(album => album.children = []);
      albums.forEach(album => {
        if (album.parent === null) return;
        const parent = albums.find(parentAlbum => parentAlbum._id === album.parent._id);
        parent.children.push(album);
      });
      const root = albums.find(album => album.parent === null);
      setTree(nodesToList([root]));
    }
    fetchTree();
  }, []);

  const handleMove = async event => {
    event.preventDefault();
    const response = await fetch("/api/photo/" + params.id + "/move/" + album);
    const responseBody = await response.json();
    alert(responseBody.message);
  }

  return (<main className="main">
    <button onClick={handleMove} className="main__button main__button_many">Переместить</button>
    <button onClick={e=>navigate(-1)} className="main__button main__button_many">Отменить</button>
    <ul className="move-form__list">{tree}</ul>
  </main>);
}