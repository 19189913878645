/**
 * 
 * @param {object} props 
 * @param {string} label - label tag innerText
 * @param {string} name - name of input tag
 * @param {boolean} required - if input is required
 * @param {function} setValue - setState()
 * @param {number} rows - number of rows
 * @param {any} value - state
 * @returns 
 */

export default function FormTextarea(props) {
  
  const handleChange = event => {
    props.setValue(event.target.value);
  };

  return (
    <>
      <label htmlFor={props.name}>{props.label}</label>
      <textarea className="form__input" name={props.name} id={props.name}
        rows={props.rows} value={props.value} required={props.required} 
        onChange={handleChange} />
    </>
  )
};