import "./Form.css";

/**
 * @function Form
 * @param {object} props
 * @param {string} classes - arbitrary classes
 * @param {function} handleSubmit - submit handling function
 * @param {string} message - message to show
 * @param {boolean} noSubmit - no submit action
 * @returns 
 */

export default function Form(props) {
  return (
    <form className={"form " + props.classes} onSubmit={props.handleSubmit}>
      {props.children}
      {!props.noSubmit && 
        <input type="submit" className="form__submit main__button"
        value="Отправить" />}
      {props.message && (<p className="form__message">{props.message}</p>)}
    </form>
  );
}