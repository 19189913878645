import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <p className="footer__text">Распространение материалов, содержащихся на
        данном веб-сайте, возможно только при соответствующем указании авторства
        в виде ссылки:</p> 
      <a  className="footer__link" href="https://nvrsk-kostomarovo.ru">
        https://nvrsk-kostomarovo.ru
      </a>
      <p className="footer__text">© В. М. Хонин,
        2008—{new Date().getFullYear()}</p>
    </footer>
  );
}

export default Footer; 
