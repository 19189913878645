import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Comments from "./Comments";
import Pager from "./Pager";

function CommentsRecent(props) {
  const navigate = useNavigate();
  const params = useParams();
  
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfItems, setNumberOfItems] = useState(0);
  
  useEffect(() => {
    async function fetchComments() {
      setIsLoading(true);
      const response = await fetch("/api/comment/recent/" + params.page);
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message, {replace: true});
      }
      setComments(responseBody.comments);
      setNumberOfItems(responseBody.total);
      setIsLoading(false);
    }
    fetchComments();
  }, [params.page])

  return (isLoading
    ? (<div className="main__loading">Загрузка...</div>)
    : (
      <main className="main">
        <h2>Последние комментарии</h2>
        <Pager numberOfItems={numberOfItems} numberOfItemsPerPage={24} />
        <Comments comments={comments} noForm={true} parentLink={true} />
        <Pager numberOfItems={numberOfItems} numberOfItemsPerPage={24} />
      </main>
    )
  )
}

export default CommentsRecent;
