import { Link, useNavigate } from "react-router-dom";

export default function ErrorPage() {
  const navigate = useNavigate();
  const message = new URLSearchParams(window.location.search).get("message");
  return (
    <main className="main">
      <h1>{message || "404 - Не найдено"}</h1>
      <button
        className="main__button main__button_many"
        onClick={()=>navigate(-1)}
      >Вернуться назад</button>
      <Link to="/" className="main__button main__link_as-button">
        Перейти на главную страницу
      </Link>
    </main>
  );
}