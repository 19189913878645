import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Form from "./Form/Form";
import FormCaptcha from "./Form/FormCaptcha";
import FormInput from "./Form/FormInput";
import { usePasswordValidation } from "../hooks/usePasswordValidation";

import "./Signin.css";

export default function ResetPassword(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [refreshCaptcha, setRefreshCaptcha] = useState(true);
  const [isValidPassword] = usePasswordValidation(
    true,
    password,
    repeatPassword,
    setMessage
  );

  const handleSubmit = async event => {
    event.preventDefault();
    if (!isValidPassword) {
      setRefreshCaptcha(true);
      return;
    }
    const formData = new FormData();
    formData.append("token", params.token);
    formData.append("password", password);
    formData.append("captchaText", captcha);
    const response = await fetch("/api/user/reset", {
      method: "POST",
      body: formData
    });
    const responseBody = await response.json();
    if (response.ok) {
      alert(responseBody.message);
      navigate("/signin", { replace: true });
    }
    setMessage(responseBody.message);
    setRefreshCaptcha(true);
  };

  return (
    <main className="main main-signin">
      <Form handleSubmit={handleSubmit} message={message}
        classes="main-signin__form">
        <FormInput type="password" name="password" value={password}
          setValue={setPassword} label="Новый пароль" />
        <FormInput type="password" name="repeat-password" value={repeatPassword}
          setValue={setRepeatPassword} label="Повторите новый пароль" /> 
        <FormCaptcha captcha={captcha} setCaptcha={setCaptcha}
          refresh={refreshCaptcha} setRefresh={setRefreshCaptcha} />
        <div>
          Пароль должен быть не короче 8 символов, а также содержать хотя бы
          одну цифру, одну заглавную и одну строчную латинские буквы.
        </div>
      </Form>
    </main>
  );
}
