/**
 * @function FormInput
 * @param {object} props 
 * @param {string} label - label tag innerText
 * @param {string} name - name of input tag
 * @param {boolean} required - if input is required
 * @param {function} setValue - setState()
 * @returns 
 */

export default function FormInputFile(props) {
  
  const handleChange = event => {
    props.setValue(event.target.files[0]);
  };

  return (
    <>
      <label htmlFor={props.name} className={props.classes}>{props.label}</label>
      <input className={"form__input " + props.classes} name={props.name}
        id={props.name} type="file"
        required={props.required} onChange={handleChange} />
    </>
  )
};