import { useEffect, useState } from "react"
import FormInput from "../Form/FormInput";
import Form from "../Form/Form";

import "./AdminTags.css";

export default function AdminTags(props) {
  const [tags, setTags] = useState([]);
  const [update, setUpdate] = useState(true);
  const [tagId, setTagId] = useState("");
  const [tagTitle, setTagTitle] = useState("");

  useEffect(() => {
    async function fetchTags() {
      const response = await fetch("/api/tag/cloud");
      const responseBody = await response.json();
      setTags(responseBody);
      setUpdate(false);
    }
    if (update) fetchTags();
  }, [update])

  const handleSelectChange = event => {
    setTagId(event.target.value);
    setTagTitle(tags.find(tag => tag._id === event.target.value).title);
  }

  const handleSave = async event => {

    const formData = new FormData();
    formData.append("title", tagTitle);
    const response = await fetch("/api/tag/" + tagId + "/rename", {
      method: "POST",
      body: formData
    });
    const responseBody = await response.json();
    alert(responseBody.message);
    if (response.ok) {
      setTagId("");
      setTagTitle("");
      setUpdate(true);
    }
  }

  const handleDelete = async event => {
    const response = await fetch("/api/tag/" + tagId + "/delete");
    const responseBody = await response.json();
    alert(responseBody.message);
    if (response.ok) {
      setTagId("");
      setTagTitle("");
      setUpdate(true);
    }
  }

  const handleCancel = async event => {
    setTagId("");
    setTagTitle("");
  }

  return (<Form noSubmit={true} classes="admin-tags__form">
    <select value={tagId} onChange={handleSelectChange} class="form__input">
      <option value="" key="0">-- Выберите тег --</option>
      {tags.map(tag => <option value={tag._id} key={tag._id}>{tag.title + " (" + tag.children + ")"}</option>)}
    </select>
    {!!tagId && <>
      <FormInput label="Название" name="tagTitle" value={tagTitle} setValue={setTagTitle}/>
      <div className="admin-tags__buttons">
        <button onClick={handleDelete} className="main__button">Удалить</button>
        <button onClick={handleCancel} className="main__button">Отменить</button>
        <button onClick={handleSave} className="main__button">Сохранить</button>
      </div>
    </>}
  </Form>)
}