import "./FormRadio.css"

/**
 * @function FormRadio
 * @param {object} props 
 * @param {object[]} props.options - list of radio inputs
 * @param {string} props.options[n].value - id/value of option
 * @param {string} props.options[n].leble - corresponding label to option
 * @param {boolean} props.options[n].default - if option is default
 * @param {string} props.propertyName - name of corresponding form property
 * @param {function} setValue - setState()
 * @returns 
 */

export default function FormRadio(props) {
  const handleChange = event => {
    props.setValue(event.target.value);
  };

  const lis = [];

  for (const [index, option] of props.options.entries()) {
    lis.push(<li className="form-radio__item" key={index}>
        <input
          className="form-radio__radio"
          type="radio"
          name={props.propertyName}
          id={option.value}
          value={option.value}
          onChange={handleChange}
          checked={props.value ? option.value === props.value : option.default} />
        <label className={"form-radio__label " + (
          index === 0 ? "form-radio__label_first" :
          index === props.options.length - 1 ? "form-radio__label_last" :
          "form-radio__label_middle"
        )}
          htmlFor={option.value}>{option.label}</label>
      </li>)
  }

  return (
    <ul className="form-radio">{lis}</ul>
  )
};