import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Form from "./Form/Form";
import FormCaptcha from "./Form/FormCaptcha";
import FormInput from "./Form/FormInput";
import FormRadio from "./Form/FormRadio";
import { usePasswordValidation } from "../hooks/usePasswordValidation";

import "./Signin.css";

export default function Signin(props) {
  const [actionType, setActionType] = useState("signin");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [refreshCaptcha, setRefreshCaptcha] = useState(true);
  const [isValidPassword] = usePasswordValidation(
    actionType === "signup",
    password,
    repeatPassword,
    setMessage
  );

  useEffect(() => setMessage(""), [actionType])

  const navigate = useNavigate();

  const handleSubmit = async event => {
    event.preventDefault();
    const formData = new FormData();
    if (actionType === "signup" && !isValidPassword) return;
    formData.append("mail", mail);
    formData.append("name", name);
    formData.append("password", password);
    formData.append("captchaText", captcha)
    const response = await fetch("/api/user/" + actionType, {
      method: "POST",
      body: formData
    });
    const responseBody = await response.json();
    if (actionType === "signin" && response.ok) {
      localStorage.setItem("userName", responseBody.userName);
      localStorage.setItem("isSignedIn", true);
      if (responseBody.isAdmin) localStorage.setItem("isAdmin", true);
      navigate("/profile", { replace: true });
    }
    setMessage(responseBody.message)
    setRefreshCaptcha(true);
  };

  return (
    <main className="main">
      <Form handleSubmit={handleSubmit} message={message}>
        <FormRadio options={[
          {value: "signin", label: "Войти", default: true},
          {value: "signup", label: "Зарегистрироваться"},
          {value: "forgot", label: "Восстановить пароль"}
          ]} propertyName="actionType" setValue={setActionType} 
          value={actionType} />
        <FormInput type="email" name="mail" value={mail} setValue={setMail}
          required={true} label="Электронная почта" />
        { actionType === "signup" &&
          <FormInput type="text" name="name" value={name} setValue={setName}
          label="Отображаемое имя" /> }
        { actionType !== "forgot" &&
          <FormInput type="password" name="password" value={password}
          setValue={setPassword} label="Пароль" /> }
        { actionType === "signup" &&
          <FormInput type="password" name="repeatPassword" value={repeatPassword}
          setValue={setRepeatPassword} label="Повторите пароль" /> }
        { actionType === "signup" &&
          <div>
            Пароль должен быть не короче 8 символов, а также содержать хотя бы
            одну цифру, одну заглавную и одну строчную латинские буквы.
          </div> }
        <FormCaptcha captcha={captcha} setCaptcha={setCaptcha}
          refresh={refreshCaptcha} setRefresh={setRefreshCaptcha} />
      </Form>
    </main>
  );
}
