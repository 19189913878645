import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function Activate() {
  const params = useParams();
  const [message, setMessage] = useState("Подождите, идёт активация...");
  
  useEffect(() => {
    async function activate() {
      const response = await fetch("/api/user/activate/" + params.id);
      const responseBody = await response.json();
      setMessage(responseBody.message);
    }
    activate();
  }, [params.id]);

  return <main className="main main-text"><p>{message}</p></main>;
}