import { useEffect, useState } from "react";

export function usePasswordValidation(isActive, password, repeatPassword, setMessage) {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (!isActive) return;
    setIsValid(false);
    setMessage("");
    if (password === "") setMessage("Пароль не может быть пустым.");
    else if (password.length < 8) setMessage("Пароль должен быть не менее 8 символов.");
    else if (password === password.toLowerCase()) setMessage("Пароль должен содержать хотя бы одну заглавную букву.");
    else if (!/\d/.test(password)) setMessage("Пароль должен содержать хотя бы одну цифру.");
    else if (password !== repeatPassword) setMessage("Новый пароль не совпадает с его повторным вводом.");
    else setIsValid(true);
  }, [isActive, password, repeatPassword])

  return [isValid];
}