import { useEffect, useState } from "react";
import GalleryPage from "./GalleryPage";

export default function GallerySpecial(props) {
  const [album, setAlbum] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchPhotos() {
      setIsLoading(true);
      const response = await fetch("/api/album/" + props.type + "/0?quantity="
        + props.quantity);
      const responseBody = await response.json();
      if (response.ok) {
        setAlbum(responseBody);
        setIsLoading(false);
      }
    }
    fetchPhotos();
  }, [props.quantity])

  return (isLoading
    ? (<div className="main__loading">Загрузка...</div>)
    : (<GalleryPage album={album} type={props.type} />)
  )
}