import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Breadcrumbs from "./Breadcrumbs";
import Comments from "./Comments";
import GalleryPage from "./GalleryPage";
import Meta from "./Meta";
import Pager from "./Pager";

export default function Album(props) {
  const params = useParams();
  const [album, setAlbum] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAlbum() {
      setIsLoading(true);
      const response = await fetch("/api/album/" + params.id + "/"
        + params.page + (params.id === "random" ? "?quantity=12" : ""));
      const responseBody = await response.json();
      if (!response.ok) {
        navigate("/error?message=" + responseBody.message, {replace: true});
      }
      setAlbum(responseBody);
      setIsLoading(false);
    }
    fetchAlbum();
  }, [params.id, params.page])

  return isLoading ? (<div className="main__loading">Загрузка...</div>) : (
    <main className="main main-album">
      <Breadcrumbs album={album} />
      {!(album._id === "recent" || album._id === "random") &&
        <Link to={"/album/recent/" + album._id}
          className="main__button main__link_as-button main__button_many">
          Последние добавления</Link>}
      {localStorage.getItem("isAdmin") && !(album._id === "recent" || album._id === "random") && <>
        <Link to={"/photo/add?album=" + album._id}
          className="main__button main__link_as-button main__button_many">
          Добавить фото</Link>
        <Link to={"/album/add?album=" + album._id}
          className="main__button main__link_as-button main__button_many">
          Добавить альбом</Link>
        <Link to={"/album/" + album._id + "/edit"}
          className="main__button main__link_as-button main__button_many">
          Редактировать альбом</Link>
      </>}
      {album.childCount > 24 && !(album._id === "recent" || album._id === "random") &&
        <Pager numberOfItems={album.childCount} numberOfItemsPerPage={24}/>}
      <GalleryPage album={album} type="album" />
      {album.childCount > 24 && !(album._id === "recent" || album._id === "random") &&
        <Pager numberOfItems={album.childCount} numberOfItemsPerPage={24}/>}
      <Meta
        title={album.title}
        description={album.description}
        created={album.created}
        views={album.views}
        tags={album.tags}
        elements={album.childCount}
      />
      {!(album._id === "recent" || album._id === "random") &&
        <Comments comments={album.comments || []}/>}
    </main>
  );
}
