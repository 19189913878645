import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Pager.css";

function Pager(props) {
  const params = useParams();
  const navigate = useNavigate();
  
  const [pageButtons, setPageButtons] = useState([]);

  useEffect(()=>{
    const items = props.numberOfItems;
    const ipp = props.numberOfItemsPerPage || 24;
    const pages = Math.ceil(items / ipp);
    const buttons = [];

    function makeButton(buttonPage) {
      return (<button
          key={buttonPage}
          onClick={handleClick}
          className={"main__button pager__button " + (buttonPage === page ? "pager__button_active " : "")}
        >{buttonPage}</button>)
    }

    let page = 1;
    let handleClick = () => {
      return;
    };
    if (params.page) {
      page = Number(params.page) + 1;
      handleClick = event => {
        let url = window.location.pathname.split('/');
        url.pop();
        url.push(event.target.innerText - 1);
        navigate(url.join("/"));
      }
    } else if (props.setPage) {
      page = Number(props.page) + 1;
      handleClick = event => {
        props.setPage(event.target.innerText - 1);
      };
    }

    buttons.push(makeButton(1))
    if (pages <= 9) {
      for (let i = 2; i < pages; i++) {
        buttons.push(makeButton(i));
      }
    }
    if (pages > 9) {
      let leftBoundary = page - 2;
      let rightBoundary = page + 2;
      if (leftBoundary < 2) {
        rightBoundary += (2 - leftBoundary);
        leftBoundary = 2;
      } else if (rightBoundary > pages - 1) {
        leftBoundary -= (rightBoundary - pages + 1);
        rightBoundary = pages - 1;
      }
      if (leftBoundary > 2) buttons.push(
        <button key="le"className="main__button pager__button pager__ellipsis">...</button>)
      for (let i = leftBoundary; i <= rightBoundary; i++) {
        buttons.push(makeButton(i));
      }
      if (rightBoundary < pages - 1) buttons.push(
        <button key="re" className="main__button pager__button pager__ellipsis">...</button>)
    }
    if (pages > 1) buttons.push(makeButton(pages))

    setPageButtons(buttons);
  }, [params, props.page, props.numberOfItems])

  return (
    <div className="pager">
      {pageButtons}
    </div>
  )
}

export default Pager;