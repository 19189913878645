/**
 * @function FormInput
 * @param {object} props 
 * @param {string} label - label tag innerText
 * @param {string} name - name of input tag
 * @param {boolean} required - if input is required
 * @param {function} setValue - setState()
 * @param {string} type - type attribute of input tag
 * @param {any} value - state
 * @param {boolean} disabled - if input is disabled
 * @returns 
 */

export default function FormInput(props) {
  
  const handleChange = event => {
    props.setValue(event.target.value);
  };

  return (
    <>
      <label htmlFor={props.name} className={props.classes}>{props.label}</label>
      <input className={"form__input " + props.classes} name={props.name}
        id={props.name} type={props.type} value={props.value}
        required={props.required} onChange={handleChange}
        disabled={props.disabled} />
    </>
  )
};